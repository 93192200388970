import { useEffect, useState } from "react";
import AddReferral from "./AddReferral";
import GeneratedReferrals from "./GeneratedReferrals";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { auth } from "../../firebase";
import { PrimaryButton } from "../Global/Button";

export interface Referral {
  id: string;
  referral: string;
}

interface ReferralTabsProps {
  report: string;
  recordingId: string;
}

export const ReferralTabs: React.FC<ReferralTabsProps> = ({
  report,
  recordingId,
}) => {
  const [activeTab, setActiveTab] = useState<"add" | "list">("add");
  const [referrals, setReferrals] = useState<Referral[]>([]);
  console.log("🚀 ~ referrals:", referrals);

  const fetchReferrals = async () => {
    //   setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-referral/user-generated-referrals`,
        {
          recordingId: recordingId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
      }
      setReferrals(response.data.generatedReferrals);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, [recordingId]);

  useEffect(() => {
    if (referrals.length > 0) {
      setActiveTab("list");
    }
  }, [referrals]);

  const handleNewReferral = (referral: Referral) => {
    setReferrals((prev) => [referral, ...prev]);
    setActiveTab("list");
  };

  const handleReferralTabs = () => {
    if (activeTab === "add") setActiveTab("list");
    else setActiveTab("add");
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-semibold mb-2">Referral</h1>
        <div className="flex gap-2">
          <PrimaryButton
            label={activeTab === "list" ? "Generate Referral" : "Referral list"}
            onClick={handleReferralTabs}
          />
        </div>
      </div>
      <div>
        {activeTab === "add" && (
          <AddReferral
            report={report}
            addNewRefferal={handleNewReferral}
            recordingId={recordingId}
          />
        )}
        {activeTab === "list" && (
          <GeneratedReferrals
            referrals={referrals}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
