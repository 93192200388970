// import React, { useState } from "react";
// import Input from "../Global/Input";
// import { PrimaryButton } from "../Global/Button";
// import CustomTemplateDialog from "../CustomTemplateDialog";
// import AudioAnimation from "../../assets/audio-animation.png";
// import useUserStore from "../../utils/userStore";

// export default function PatientInformation({
//   patientName,
//   setPatientName,
//   startRecording,
//   setIsRecording,
//   setSelectedLanguage,
//   setIsPatientForm,
//   selectedOption,
//   handleOptionChange,
//   setSelectedSpecialization,
//   handleSelectedCustomTemplate,
//   selectedTemplateName,
// }) {
//   const [showCustomModal, setShowCustomModal] = useState(false);
//   const currentUser = useUserStore((state) => state.currentUser);
//   console.log("🚀 ~ setCurrentUser:", currentUser);

//   const handleCustomModal = () => {
//     setShowCustomModal(!showCustomModal);
//   };

//   return (
//     <div className="flex gap-2 flex-col text-center items-center justify-center">
//       <div className="flex item-center justify-center pt-8 md:p-0">
//         <img src={AudioAnimation} alt="animation" />
//       </div>
//       <p className="font-semibold">Start by saving patient’s name</p>
//       <div>
//         <p className="text-sm text-customBlack-text ">
//           Any recordings done here will be saved in recordings
//         </p>

//         <p className="text-sm text-customBlack-text ">section on the left.</p>
//         <p className="text-sm text-customBlack-text ">
//           Hit the record button once you type in the patient name
//         </p>
//       </div>
//       <div className="w-full">
//         <div className="flex">
//           <select
//             id="language"
//             name="Language"
//             className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
//             onChange={(e) => setSelectedLanguage(e.target.value)}
//           >
//             <option value="en-US">English</option>
//             <option value="zh-HK" disabled={currentUser.plan === "0"}>
//               Cantonese
//             </option>
//             <option value="fr-CA" disabled={currentUser.plan === "0"}>
//               French
//             </option>
//             <option value="de" disabled={currentUser.plan === "0"}>
//               German
//             </option>
//             <option value="hi" disabled={currentUser.plan === "0"}>
//               Hindi
//             </option>
//             <option value="it" disabled={currentUser.plan === "0"}>
//               Italian
//             </option>
//             <option value="zh-TW" disabled={currentUser.plan === "0"}>
//               Mandarin
//             </option>
//             <option value="pt" disabled={currentUser.plan === "0"}>
//               Portugese
//             </option>
//             <option value="multi" disabled={currentUser.plan === "0"}>
//               Spanish
//             </option>
//             {/* <option value="ur-PK">Urdu</option>
//             <option value="ar-SA">Arabic</option> */}
//             {/* <option value="pa-IN">Punjabi</option> */}
//           </select>
//         </div>

//         <Input
//           label="Patient's name"
//           type="text"
//           placeholder="Enter patients name"
//           value={patientName}
//           handleOnChange={(e) => setPatientName(e.target.value)}
//           isRequired={true}
//         />

//         <>
//           <div className="">
//             <div className="flex items-center mb-4">
//               <input
//                 type="radio"
//                 id="SOAP"
//                 name="options"
//                 value="SOAP"
//                 checked={selectedOption === "SOAP"}
//                 onChange={handleOptionChange}
//                 className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
//               />
//               <label
//                 htmlFor="SOAP"
//                 className="ml-2 block text-sm text-gray-900"
//               >
//                 SOAP Notes
//               </label>
//             </div>
//             <div className="flex items-center mb-4">
//               <input
//                 type="radio"
//                 id="consultation"
//                 name="options"
//                 value="consultation"
//                 checked={selectedOption === "consultation"}
//                 onChange={handleOptionChange}
//                 className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
//               />
//               <label
//                 htmlFor="consultation"
//                 className="ml-2 block text-sm text-gray-900"
//               >
//                 Consultation Notes
//               </label>
//             </div>
//             <div className="flex items-center mb-4">
//               <input
//                 type="radio"
//                 id="custom"
//                 name="options"
//                 value="custom"
//                 checked={selectedOption === "custom"}
//                 disabled={currentUser.plan === "0"}
//                 onChange={(e) => {
//                   handleOptionChange(e, handleCustomModal);
//                 }}
//                 className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
//               />
//               <label
//                 htmlFor="custom"
//                 className="ml-2 block text-sm text-gray-900"
//               >
//                 Custom Templates
//               </label>

//               <div className="flex justify-end w-1/2">
//                 {selectedTemplateName}
//               </div>
//             </div>
//           </div>
//           {selectedOption === "consultation" && (
//             <div className="flex">
//               <select
//                 id="occupation"
//                 name="occupation"
//                 className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
//                 onChange={(e) => setSelectedSpecialization(e.target.value)}
//               >
//                 <option value="">Select Specialization</option>
//                 <option value="internal_medicine">Internal Medicine</option>
//                 <option value="general_surgery">General Surgery</option>
//                 <option value="anesthesiology">Anesthesiology</option>
//                 <option value="pediatrics">Pediatrics</option>
//                 <option value="obstetrics_gynecology">
//                   Obstetrics & Gynecology
//                 </option>
//                 <option value="psychiatry">Psychiatry</option>
//                 <option value="ophthalmology">Ophthalmology</option>
//                 <option value="otolaryngology">Otolaryngology (ENT)</option>
//                 <option value="orthopedic_surgery">Orthopedic Surgery</option>
//                 <option value="dermatology">Dermatology</option>
//               </select>
//             </div>
//           )}
//         </>
//         <PrimaryButton
//           label={"Record"}
//           onClick={async () => {
//             await startRecording();
//             // setPauseRecording(true);
//             setIsRecording(true);
//             setIsPatientForm(true);
//           }}
//           isDisabled={
//             !!!patientName ||
//             (selectedOption === "custom" && !!!selectedTemplateName)
//           }
//         />
//       </div>

//       {showCustomModal && (
//         <CustomTemplateDialog
//           handleCloseCustomModal={handleCustomModal}
//           handleSelectedCustomTemplate={handleSelectedCustomTemplate}
//         />
//       )}
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import Input from "../Global/Input";
import { PrimaryButton } from "../Global/Button";
import CustomTemplateDialog from "../CustomTemplateDialog";
import AudioAnimation from "../../assets/audio-animation.png";
import useUserStore from "../../utils/userStore";
import axios from "axios";
import { auth } from "../../firebase";
import PatientNameInput from "./PatientInput";

export default function PatientInformation({
  patientName,
  setPatientName,
  startRecording,
  setIsRecording,
  setSelectedLanguage,
  setIsPatientForm,
  isPatientForm,
  setActiveTab,
  selectedOption,
  handleOptionChange,
  setSelectedSpecialization,
  handleSelectedCustomTemplate,
  selectedTemplateName,
}) {
  const [showCustomModal, setShowCustomModal] = useState(false);
  const currentUser = useUserStore((state) => state.currentUser);
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const [templates, setTemplates] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    if (!currentUser || Object.keys(currentUser).length === 0) {
      const storedUser = localStorage.getItem("currentUser");
      if (storedUser) {
        setCurrentUser(JSON.parse(storedUser));
      }
    }
  }, [currentUser, setCurrentUser]);

  const getTemplates = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.get(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-template`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTemplates(response.data.doctorTemplates);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // Example: 2 random names for the special user
  const specialPatientNames = [
    "9:15 AM - Emily Carter",
    "9:30 AM - Liam Johnson",
    "9:45 AM - Sophia Martinez",
    "10:00 AM - Noah Williams",
    "10:30 AM - Ava Brown",
    "10:45 AM - Ethan Davis",
  ];

  const handleCustomModal = () => {
    setShowCustomModal(!showCustomModal);
  };

  const handleSpecialNameChange = (e) => {
    setPatientName(e.target.value);
  };

  const handleAddTemplate = async (fileContent, fileName) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-template`,
        {
          template: fileContent,
          name: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowUploadModal(false);
      setTemplates([...templates, response.data.doctorTemplate]);
      toast.success("Template added successfully");
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  return (
    <div className="flex gap-2 flex-col text-center items-center justify-center">
      <div className="flex item-center justify-center pt-8 md:p-0">
        <img src={AudioAnimation} alt="animation" />
      </div>
      <p className="font-semibold">Start by saving patient’s name</p>
      <div>
        <p className="text-sm text-customBlack-text">
          Any recordings done here will be saved in recordings
        </p>
        <p className="text-sm text-customBlack-text">section on the left.</p>
        <p className="text-sm text-customBlack-text">
          Hit the record button once you type in the patient name
        </p>
      </div>

      <div className="w-full">
        {/* Language Select */}
        <div className="flex">
          <select
            id="language"
            name="Language"
            className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="en-US">English</option>
            <option value="zh-HK" disabled={currentUser.plan === "0"}>
              Cantonese
            </option>
            <option value="fr-CA" disabled={currentUser.plan === "0"}>
              French
            </option>
            <option value="de" disabled={currentUser.plan === "0"}>
              German
            </option>
            <option value="hi" disabled={currentUser.plan === "0"}>
              Hindi
            </option>
            <option value="it" disabled={currentUser.plan === "0"}>
              Italian
            </option>
            <option value="zh-TW" disabled={currentUser.plan === "0"}>
              Mandarin
            </option>
            <option value="pt" disabled={currentUser.plan === "0"}>
              Portugese
            </option>
            <option value="multi" disabled={currentUser.plan === "0"}>
              Spanish
            </option>
          </select>
        </div>

        
          {/* <Input
            label="Patient's name"
            type="text"
            placeholder="Enter patient's name"
            value={patientName}
            handleOnChange={(e) => setPatientName(e.target.value)}
            isRequired={true}
          /> */}
      <PatientNameInput patientName={patientName} setPatientName={setPatientName} />

        {/* Radio Buttons */}
        <>
          <div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="SOAP"
                name="options"
                value="SOAP"
                checked={selectedOption === "SOAP"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="SOAP"
                className="ml-2 block text-sm text-gray-900"
              >
                SOAP Notes
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="consultation"
                name="options"
                value="consultation"
                checked={selectedOption === "consultation"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="consultation"
                className="ml-2 block text-sm text-gray-900"
              >
                Consultation Notes
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="custom"
                name="options"
                value="custom"
                checked={selectedOption === "custom"}
                disabled={currentUser.plan === "0"}
                onChange={(e) => {
                  handleOptionChange(e, handleCustomModal);
                }}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="custom"
                className="ml-2 block text-sm text-gray-900"
              >
                Custom Templates
              </label>

              <div className="flex justify-end w-1/2">
                {selectedTemplateName}
              </div>
            </div>
          </div>

          {selectedOption === "consultation" && (
            <div className="flex">
              <select
                id="occupation"
                name="occupation"
                className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
                onChange={(e) => setSelectedSpecialization(e.target.value)}
              >
                <option value="">Select Specialization</option>
                <option value="internal_medicine">Internal Medicine</option>
                <option value="general_surgery">General Surgery</option>
                <option value="anesthesiology">Anesthesiology</option>
                <option value="pediatrics">Pediatrics</option>
                <option value="obstetrics_gynecology">
                  Obstetrics & Gynecology
                </option>
                <option value="psychiatry">Psychiatry</option>
                <option value="ophthalmology">Ophthalmology</option>
                <option value="otolaryngology">Otolaryngology (ENT)</option>
                <option value="orthopedic_surgery">Orthopedic Surgery</option>
                <option value="dermatology">Dermatology</option>
              </select>
            </div>
          )}
        </>

        {/* Record Button */}
        <PrimaryButton
          label={"Record"}
          onClick={async () => {
            setIsPatientForm(true);
            setActiveTab("transcript");
            await startRecording();
            setIsRecording(true);
          }}
          isDisabled={
            !!!patientName ||
            (selectedOption === "custom" && !!!selectedTemplateName)
          }
        />
      </div>

      {showCustomModal && (
        <CustomTemplateDialog
          handleCloseCustomModal={handleCustomModal}
          handleSelectedCustomTemplate={handleSelectedCustomTemplate}
          templates={templates}
          setTemplates={setTemplates}
          handleAddTemplate={handleAddTemplate}
          handleUploadModal={handleUploadModal}
          showUploadModal={showUploadModal}
        />
      )}
    </div>
  );
}
