// import React from "react";
// import Pricing from "../components/Main/sections/Pricing";
// import Features from "../components/Main/sections/Features";
// import Testimonials from "../components/Main/sections/Testimonials";
// import Footer from "../components/Main/sections/Footer";
// import "../styles/LandingPage.css";
// import HeroImg from "../assets/hero.png";
// import FAQ from "../components/Main/sections/FAQ";
// import OurTeam from "../components/Main/sections/OurTeam";
// import AppBanners from "../components/Main/sections/AppBanners";
// import ContactForm from "../components/Main/sections/ContactForm";
// import { useNavigate } from "react-router-dom";

// const LandingPage = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="container mx-auto px-4 flex flex-col gap-8">
//       <div className="text-center my-10 flex flex-col items-center">
//         <p className="text-4xl md:text-[50px] leading-snug font-bold md:w-[60%]">
//           Transform Doctor-Patient Conversation into Accurate, Automated SOAP
//           Notes in Real-Time
//         </p>
//         <p className="text-xl mt-2 md:w-[55%] text-customBlack-secondary">
//           Simplify your documentation process with MD Voice. Record
//           doctor-patient conversations in real-time, generate accurate
//           transcripts, and instantly transform them into detailed medical notes.
//           Say goodbye to manual note-taking and focus more on patient care. MD
//           Voice ensures you stay efficient, organized, and compliant—all with
//           the power of your voice.
//         </p>

//         <button
//           onClick={() => {
//             navigate("/signup");
//           }}
//           className="btn-secondary border border-customBlue-secondary py-2 px-4 mt-4"
//         >
//           Get Started for Free
//         </button>
//       </div>
//       <div className="flex justify-center my-10">
//         <img src={HeroImg} alt="Description" className="" />
//       </div>
//       <AppBanners />
//       <Features />
//       <Pricing isLandingPage={true} />
//       <Testimonials />
//       <OurTeam />
//       <FAQ />
//       <ContactForm />
//       <Footer />
//     </div>
//   );
// };

// export default LandingPage;
import React, { Suspense, lazy } from "react";
import "../styles/LandingPage.css";
import HeroImg from "../assets/hero.png"; // Convert to WebP
import { useNavigate } from "react-router-dom";

// Lazy Load Components
const Features = lazy(() => import("../components/Main/sections/Features"));
const Pricing = lazy(() => import("../components/Main/sections/Pricing"));
const Testimonials = lazy(
  () => import("../components/Main/sections/Testimonials")
);
const FAQ = lazy(() => import("../components/Main/sections/FAQ"));
const OurTeam = lazy(() => import("../components/Main/sections/OurTeam"));
const AppBanners = lazy(() => import("../components/Main/sections/AppBanners"));
const ContactForm = lazy(
  () => import("../components/Main/sections/ContactForm")
);
const Footer = lazy(() => import("../components/Main/sections/Footer"));

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      {/* Header Section */}
      <div className="text-center my-10 flex flex-col items-center">
        <p className="text-4xl md:text-[50px] leading-snug font-bold md:w-[60%]">
          Transform Doctor-Patient Conversation into Accurate, Automated SOAP
          Notes in Real-Time
        </p>
        <p className="text-xl mt-2 md:w-[55%] text-customBlack-secondary">
          Simplify your documentation process with MD Voice. Record
          conversations in real-time, generate accurate transcripts, and
          transform them into detailed medical notes.
        </p>

        <button
          onClick={() => navigate("/signup")}
          className="btn-secondary border border-customBlue-secondary py-2 px-4 mt-4"
        >
          Get Started for Free
        </button>
      </div>

      {/* Hero Image - Lazy Load */}
      <div className="flex justify-center my-10">
        <img
          src={HeroImg}
          alt="Doctor-Patient Conversation"
          loading="lazy"
          className="w-full max-w-[800px] h-auto"
        />
      </div>

      {/* Lazy Load Sections */}
      <Suspense fallback={<div>Loading...</div>}>
        <AppBanners />
        <Features />
        <Pricing isLandingPage={true} />
        <Testimonials />
        {/* <OurTeam /> */}
        {/* <FAQ /> */}
        {/* <ContactForm /> */}
        <Footer />
      </Suspense>
    </div>
  );
};

export default LandingPage;
