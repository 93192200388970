import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/Navbar.css";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header>
      <nav className="p-4 border-b border-customGrey-primary">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link to="/">
            <img src={Logo} alt="Company Logo" className="h-12" />
          </Link>

          {/* Desktop Middle Links */}
          <div className="hidden md:flex justify-center flex-1">
            <div className="flex items-center gap-6">
              <Link
                to="/team"
                className="text-sm font-medium hover:text-customBlue-secondary"
              >
                Team
              </Link>
              <Link
                to="/faqs"
                className="text-sm font-medium hover:text-customBlue-secondary"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="text-sm font-medium hover:text-customBlue-secondary"
              >
                Contact Us
              </Link>
            </div>
          </div>

          {/* Login/Signup and Hamburger */}
          <div className="flex items-center gap-4">
            {/* Login/Signup always visible */}
            <div className="flex flex-col gap-2 items-end">
              <div className="flex flex-row space-x-4">
                <Link
                  to="/login"
                  className="btn-primary border border-customBlack-primary font-semibold"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="btn-secondary border border-customBlue-secondary font-semibold"
                >
                  Try It - It's Free!
                </Link>
              </div>
              <p className="hidden md:block text-xs">No credit card required</p>
            </div>

            {/* Hamburger for mobile only */}
            <div className="md:hidden">
              <button
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                type="button"
                aria-label="Toggle Menu"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Middle Links Dropdown */}
        <div
          className={`${isMobileMenuOpen ? "block" : "hidden"} md:hidden mt-4`}
        >
          <div className="flex flex-col gap-4 items-center">
            <Link
              to="/team"
              className="text-sm font-medium hover:text-customBlue-secondary"
            >
              Team
            </Link>
            <Link
              to="/faqs"
              className="text-sm font-medium hover:text-customBlue-secondary"
            >
              FAQs
            </Link>
            <Link
              to="/contact"
              className="text-sm font-medium hover:text-customBlue-secondary"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
