import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/NotesList.css";
import { VscKebabVertical } from "react-icons/vsc";
import { TbEye } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import useRecordingStore from "../../utils/recordingStore";
import { MdArrowBack } from "react-icons/md";
import axios from "axios";
import useUserStore from "../../utils/userStore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const getTimestampsForSelectedDate = (selectedDate) => {
  // Create a Date object for the start of the selected date
  const startDate = new Date(selectedDate);
  startDate.setHours(0, 0, 0, 0);

  // Create a Date object for the start of the next day
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 1);

  return {
    startTimestamp: startDate,
    endTimestamp: endDate,
  };
};

const getDateFromSeconds = (seconds) => {};

const NotesList = ({ selectedRecord, setSelectedRecord, reloadData }) => {
  const [notes, setNotes] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const searchName = useRecordingStore((state) => state.searchName);
  const setSearchName = useRecordingStore((state) => state.setSearchName);
  const recordings = useRecordingStore((state) => state.recordings);
  const setRecordings = useRecordingStore((state) => state.setRecordings);
  // const [recordings, setRecordings] = useState([]);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const selectedDate = useRecordingStore((state) => state.selectedDate);
  const setSelectedDate = useRecordingStore((state) => state.setSelectedDate);
  const dropdownRef = useRef(null);
  const [recordingsDict, setRecordingsDict] = useState({});
  const currentUser = useUserStore((state) => state.currentUser);
  const uid = useUserStore((state) => state.uid);
  const navigate = useNavigate();
  const setUid = useUserStore((state) => state.setUid);

  useEffect(() => {
    // 1. Check if uid is in your Zustand store
    // 2. If it’s not there, try to get it from localStorage
    let finalUid = uid;
    if (!finalUid) {
      const storedUid = localStorage.getItem("uid");
      if (storedUid) {
        finalUid = storedUid;
        setUid(storedUid); // Update the store, if you’d like
      }
    }
    // Only fetch if you have a finalUid or a currentUser
    if (currentUser?.user_id || finalUid) {
      const fetchRecordings = async () => {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          if (!user) {
            toast.error("User not authenticated");
            navigate("/login");
          }
          const token = await user.getIdToken();

          const response = await axios.post(
            `${process.env.REACT_APP_BE_DOMAIN}/user/get-recordings`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = response.data;
          if (searchName) {
            setRecordings(
              data.filter((doc) =>
                doc.patient_name
                  .toLowerCase()
                  .includes(searchName.toLowerCase())
              )
            );
          } else {
            const storedDrafts = localStorage.getItem("draftNotes");
            if (storedDrafts) {
              const draftNotes = JSON.parse(storedDrafts);
              setRecordings([...draftNotes, ...data]);
            } else {
              setRecordings(data);
            }
          }
        } catch (error) {
          console.error("Error fetching recordings:", error);
          // Optionally navigate or handle error here
        }
      };

      fetchRecordings();
    }
  }, [reloadData, uid, currentUser, searchName, setUid]);

  const getDateKey = (seconds) => {
    const milliseconds = seconds * 1000;
    const date = new Date(milliseconds);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const filteredRecordings = useMemo(() => {
    const tempRecordingsDict = {};
    recordings.forEach((recording) => {
      if (recording.timestamp && recording.timestamp._seconds) {
        const dateKey = getDateKey(recording.timestamp._seconds);
        if (!tempRecordingsDict[dateKey]) {
          tempRecordingsDict[dateKey] = [];
        }
        tempRecordingsDict[dateKey].push(recording);
      }
    });
    return tempRecordingsDict;
  }, [recordings]);
  // useEffect(() => {
  //   // Function to convert seconds to Date object and format date as string "YYYY-MM-DD"
  //   const getDateKey = (seconds) => {
  //     const milliseconds = seconds * 1000;
  //     const date = new Date(milliseconds);
  //     return `${date.getFullYear()}-${(date.getMonth() + 1)
  //       .toString()
  //       .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  //   };
  //   // Populate the recordings dictionary
  //   const tempRecordingsDict = {};
  //   recordings.forEach((recording) => {
  //     const dateKey = getDateKey(recording.timestamp._seconds);
  //     if (!tempRecordingsDict[dateKey]) {
  //       tempRecordingsDict[dateKey] = [];
  //     }
  //     tempRecordingsDict[dateKey].push(recording);
  //   });

  //   // Set the state with the populated dictionary
  //   setFilteredRecordings(tempRecordingsDict);
  //   setRecordingsDict(tempRecordingsDict);
  // }, [recordings]);

  useEffect(() => {
    if (Object.keys(recordingsDict).length > 0) {
      if (!searchName) {
        setFilteredRecordings(recordingsDict);
        return;
      }

      // Filter recordings by patient name
      const filtered = Object.keys(recordingsDict).reduce((acc, date) => {
        const filteredByDate = recordingsDict[date].filter((recording) =>
          recording.patient_name
            .toLowerCase()
            .includes(searchName.toLowerCase())
        );
        if (filteredByDate.length > 0) {
          acc[date] = filteredByDate;
        }
        return acc;
      }, {});

      setFilteredRecordings(filtered);
    }
  }, [searchName, recordings]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenMenuIndex(null);
    }
  };

  const handleRecordClick = (record) => {
    setSelectedRecord(record);
    navigate("/main", { state: { section: "home" } });
  };

  const toggleMenu = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleBackClick = () => {
    setSelectedDate(null);
  };

  return (
    <div ref={dropdownRef} className="">
      {!selectedDate ? (
        <div className="">
          {Object.keys(filteredRecordings).map((date, index) => (
            <div
              key={date}
              className="border-b border-customBlack-light text-sm flex items-center justify-between 
                       px-2 py-4 cursor-pointer hover:bg-gray-100"
              onClick={() => handleDateSelect(date)}
            >
              <div className="flex flex-col gap-2">
                <p>{date}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <RecordingsList
          date={selectedDate}
          recordings={filteredRecordings[selectedDate]}
          onBackClick={handleBackClick}
          handleRecordClick={handleRecordClick}
        />
      )}
    </div>
  );
};

const RecordingsList = ({
  date,
  recordings,
  onBackClick,
  handleRecordClick,
}) => {
  return (
    <div>
      <div className="flex items-center px-2 py-4 justify-between border-b border-customBlack-light">
        {/* <button onClick={onBackClick}>Back</button> */}
        <MdArrowBack onClick={onBackClick} className="cursor-pointer" />

        <h2 className="flex gap-2">
          Recordings for:
          <strong>{date}</strong>
        </h2>
      </div>
      <ul>
        {recordings?.map((recording, index) => (
          <div
            className="border-b border-customBlack-light text-sm flex items-center justify-between px-2 py-4 cursor-pointer hover:bg-gray-100"
            key={index}
            onClick={() => handleRecordClick(recording)}
          >
            <p>{`${recording?.isDraft ? "Draft -" : ""} Patient Name: ${recording.patient_name}`}</p>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default NotesList;
