import React, { useEffect, useState } from "react";
import ReferralImg from "../../assets/referral.png";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import CustomTemplateDialog, { Template } from "../CustomTemplateDialog";
import { toast } from "react-toastify";
import axios from "axios";
import { auth } from "../../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Referral } from "./ReferralTabs";

interface AddReferralProps {
  report: string;
  addNewRefferal: (referral: Referral) => void;
  recordingId: string;
}

const AddReferral: React.FC<AddReferralProps> = ({
  report,
  addNewRefferal,
  recordingId,
}) => {
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedCustomTemplate, setSelectedCustomTemplate] =
    useState<Template>({ id: "", name: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [generatedReferral, setGeneratedReferral] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const getTemplates = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.get(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-referral`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTemplates(response.data.doctorReferrals);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const handleCustomModal = () => {
    setShowCustomModal(!showCustomModal);
  };

  const handleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const handleAddTemplate = async (fileContent: string, fileName: string) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-referral`,
        {
          referral: fileContent,
          name: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowUploadModal(false);
      setTemplates([...templates, response.data.doctorReferral]);
      toast.success("Referral added successfully");
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleSelectedCustomReferral = (template: Template) => {
    setSelectedCustomTemplate(template);
  };

  const handleGenerateReferral = async () => {
    setLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        toast.error("User not authenticated");
        navigate("/login");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-referral/generate-referral`,
        {
          report: report,
          referralType: selectedCustomTemplate?.id ? "custom" : "generic",
          selectedCustomTemplateId: selectedCustomTemplate?.id,
          recordingId: recordingId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data) {
        toast.error("Failed to generate referral");
        // throw new Error("Failed to generate referral");
      }
      const data = await response.data;
      addNewRefferal({ referral: data.referral, id: data.referralId });
      toast.success("Referral added successfully!");
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="h-full flex flex-col">
        <div className="flex flex-col justify-center items-center">
          <img src={ReferralImg} alt="Referral" className="mb-4" />
          <div className="flex flex-col gap-2">
            <PrimaryButton
              label="Generate Referral"
              onClick={handleGenerateReferral}
              isDisabled={loading}
            />
            <SecondaryButton
              label="Custom Referral"
              onClick={handleCustomModal}
            />
          </div>
          <div className="p-2">
            {selectedCustomTemplate.name && (
              <p>Selected Custom Referral:{selectedCustomTemplate.name}</p>
            )}
          </div>
        </div>
      </div>
      {showCustomModal && (
        <CustomTemplateDialog
          handleCloseCustomModal={handleCustomModal}
          handleSelectedCustomTemplate={handleSelectedCustomReferral}
          templates={templates}
          setTemplates={setTemplates}
          handleAddTemplate={handleAddTemplate}
          handleUploadModal={handleUploadModal}
          showUploadModal={showUploadModal}
        />
      )}
    </div>
  );
};

export default AddReferral;
