import { useState, useEffect, useCallback } from "react";
import { RefreshCcw } from "lucide-react";
import axios from "axios";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import useUserStore from "../../utils/userStore";

const PatientNameInput = ({ patientName, setPatientName }) => {
  const [customName, setCustomName] = useState(false);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useUserStore((state) => state.currentUser);
  console.log(currentUser);
  const storedUser = localStorage.getItem("currentUser"); // Get the stored value
  const parsedUser = storedUser ? JSON.parse(storedUser) : null; // Parse it safely
  const userId = currentUser?.user_id || parsedUser?.user_id; // Use currentUser first, fallback to localStorage

  const isBasicPlan = currentUser?.plan === "0" || parsedUser?.plan === "0";
  console.log("isBasic",isBasicPlan)

  // Fetch Patients Data
  const fetchPatients = useCallback(async (forceRefresh = false) => {

    if (isBasicPlan) {
      console.log("Skipping API call: User is on a free plan");
      return; }// Prevent API call for free users
  
    if (!forceRefresh && patients.length > 0) return;
  
    setLoading(true);
    const toastId = toast.info("Fetching patient data...", {
      autoClose: false,
    });
  
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
  
      const token = await user.getIdToken();
      const { data } = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/emr-info/scheduleData`,
        {
          userId: userId,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      setPatients(data || []);
      toast.success("Patients data fetched successfully!");
    } catch (error) {
      console.error("Error fetching patients:", error);
      toast.error(
        error.response?.data?.error ||
          error.message ||
          "Error fetching patients"
      );
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  }, [isBasicPlan, userId]); 

  // Auto-fetch on mount if not on basic plan
  useEffect(() => {
    if (!isBasicPlan) {
      fetchPatients();
    }
  }, [fetchPatients, isBasicPlan]);

  // Handle Patient Selection or Custom Name
  const handleNameChange = (e) => {
    const value = e.target.value;

    if (value === "+ Custom Name") {
      setCustomName(true);
      setPatientName("");
    } else {
      setCustomName(false);
      setPatientName(value);
    }
  };

  // Refresh Data
  const handleRefresh = () => {
    fetchPatients(true);
    setCustomName(false);
    setPatientName("");
  };

  // Input Component for Basic Plan
  if (isBasicPlan) {
    return (
      <div className="relative w-full max-w-sm mb-2">
        <input
          type="text"
          placeholder="Enter patient's name"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          className="border rounded-md px-3 py-2 w-full"
          required
        />
      </div>
    );
  }

  return (
    <div className="relative w-full max-w-sm mb-2">
      {customName ? (
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Enter patient's name"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
            className="border rounded-md px-3 py-2 w-full pr-12"
            required
          />
        </div>
      ) : (
        <div className="relative w-full">
          <select
            onChange={handleNameChange}
            value={patientName || ""}
            className="border rounded-md px-3 py-2 w-full pr-12 appearance-none"
            required
          >
            <option value="" disabled>
              Select a patient
            </option>
            {patients.map((patient, index) => (
              <option key={index} value={`${patient.name} (${patient.time})`}>
                {`${patient.name} - ${patient.time}`}
              </option>
            ))}
            <option value="+ Custom Name">+ Custom Name</option>
          </select>
          <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none text-gray-500">
            ▼
          </span>
        </div>
      )}

      {/* Refresh Button */}
      <button
        onClick={handleRefresh}
        disabled={loading}
        className="absolute inset-y-0 right-10 flex items-center bg-transparent p-0 border-none"
      >
        <RefreshCcw
          size={16}
          className={
            loading
              ? "animate-spin text-gray-400"
              : "text-gray-500 hover:text-gray-700"
          }
        />
      </button>
    </div>
  );
};

export default PatientNameInput;
