import React, { useState } from "react";
import InPlan from "../../../assets/in-plan.png";
import NotInPlan from "../../../assets/not-in-plan.png";
import { ToastContainer, toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../../utils/userStore";
import { auth } from "../../../firebase";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const PricingTable = () => {
  const [promoCode, setPromoCode] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);
  const navigate = useNavigate();

  const handleCheckout = async (planId: string) => {
    const stripe = await stripePromise;

    try {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();
      if (planId !== "0") {
        const response = await axios.post(
          `${process.env.REACT_APP_BE_DOMAIN}/stripe/create-checkout-session`,
          {
            userId: currentUser.user_id,
            priceId:
              planId === "premium"
                ? process.env.REACT_APP_PRICE_PREMIUM
                : process.env.REACT_APP_PRICE_STANDARD,
            planName: planId,
            promotionCode: promoCode,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
      }
    } catch (e: any) {
      toast.error(e.response?.data?.error || "An error occurred");
    }
  };

  const plans = [
    {
      name: "Free",
      price: "$0/Lifetime",
      id: "0",
      features: ["100/month", "✔", "✔", "✔", "✖", "✖", "✖", "✖", "✖"],
    },
    {
      name: "Standard",
      price: "$75/Month",
      id: "standard",
      features: ["Unlimited", "✔", "✔", "✔", "✔", "✔", "✖", "✖", "✖"],
    },
    {
      name: "Premium",
      price: "$100/Month",
      id: "premium",
      features: ["Unlimited", "✔", "✔", "✔", "✔", "✔", "✔", "✔", "✔"],
    },
  ];

  const features = [
    "Transcription",
    "Mobile App",
    "Accurate Transcription",
    "Customer Service",
    "Multiple Languages",
    "Referrals",
    "Custom Notes",
    "EMR",
    "Billing",
  ];

  return (
    <div className="p-6 md:p-12">
      <div className="overflow-x-auto rounded-lg border border-gray-300">
        <table className="w-full table-auto md:table-fixed border-collapse text-center rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-gray-50">
              {/* Desktop: Show "Price Comparison" */}
              <th className="p-4 border text-left font-bold text-[18px] text-[#101828] bg-white">
                <div className="hidden md:table-cell">Price Comparison</div>
                {/* <br /><br /> */}
                <div className="text-[16px] md:mt-9">Features</div>
              </th>
              {/* Pricing Columns */}
              {plans.map((plan) => (
                <th
                  key={plan.id}
                  className="p-4 border text-center font-bold text-[16px] text-[#101828] bg-white"
                >
                  <div>{plan.name}</div>
                  <div className="flex items-center justify-center gap-1 flex-wrap">
                    <span className="text-[32px] font-extrabold">
                      {plan.price.split("/")[0]}
                    </span>
                    <span className="text-[12px] text-[#101828]">
                      /{plan.price.split("/")[1]}
                    </span>
                  </div>
                  {/* Desktop: Show buttons */}
                  <div className="pt-3 hidden md:flex">
                    {plan.id === currentUser.plan ? (
                      <span className="border-2 border-[#57BAC1] text-[#57BAC1] px-4 py-2 rounded-lg font-bold w-full block text-center flex items-center justify-center h-[44px]">
                        {`You are on ${currentUser.plan} plan`}
                      </span>
                    ) : (
                      <button
                        className="bg-[#57BAC1] text-white px-4 py-2 rounded-lg font-bold w-full flex items-center justify-center h-[44px]"
                        onClick={() => handleCheckout(plan.id)}
                      >
                        Get started
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, i) => (
              <tr key={i} className="border bg-gray-50">
                <td className="p-4 border text-[14px] text-[#101828] text-left bg-white">
                  {feature}
                </td>
                {plans.map((plan) => (
                  <td key={plan.id} className="p-4 border text-center bg-white">
                    {feature === "Transcription" ? (
                      <span className="text-[14px] text-[#101828] font-medium">
                        {plan.features[i]}
                      </span>
                    ) : (
                      <img
                        src={plan.features[i] === "✔" ? InPlan : NotInPlan}
                        alt="Check"
                        className="mx-auto"
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PricingTable;
