import React from "react";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import RecordIcon from "../../assets/record-icon.png";
import RecordGreenIcon from "../../assets/record-icon-green.png";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { Play } from "lucide-react";
import { Pause } from "lucide-react";
import useUserStore from "../../utils/userStore";

export default function TranscriptSection({
  selectedRecord,
  pauseRecording,
  userTranscript,
  formattedPromptSOAP,
  resetRecordingState,
  handleTranscriptChange,
  handlePause,
  handleSubmitResponse,
  recorderControls,
}) {
  
  return (
    <div
    //     className={`w-full
    // ${formattedPromptSOAP ? "md:w-[66%]" : "md:w-[66%] "}
    // border-r h-screen `}
    >
      <textarea
        value={userTranscript}
        className="border-none w-full"
        rows="10"
        placeholder="Transcription will appear here..."
        onChange={handleTranscriptChange}
      />

      <div className="flex flex-col items-center">
        <div className="gap-2 items-center">
          <div
            className={`flex gap-2 border rounded-3xl p-2 items-center justify-center`}
          >
            {recorderControls?.mediaRecorder && pauseRecording ? (
              <LiveAudioVisualizer
                mediaRecorder={recorderControls.mediaRecorder}
                width={250}
                height={40}
                barColor={pauseRecording ? "#57BAC1" : ""}
              />
            ) : (
              <div className="w-[250px] h-[40px] flex items-center">
                <div className="w-full h-[2px] bg-[#57BAC1] opacity-85"></div>
              </div>
            )}
            {/* <img
              src={pauseRecording ? RecordGreenIcon : RecordIcon}
              alt="record icon"
              className="cursor-pointer"
              onClick={handlePause}
              /> */}
            {pauseRecording ? (
              <Pause color="#57BAC1" onClick={handlePause} size={30} />
            ) : (
              <Play color="#57BAC1" onClick={handlePause} size={30} />
            )}
          </div>
          <p className="text-lg">
            {!pauseRecording
              ? "Recording Paused(Click button to start recording)"
              : "Currently Recording(Click button to stop recording)"}
          </p>
        </div>
        <p className="text-customBlack-text">
          {pauseRecording
            ? "Press the record icon to stop recording"
            : "Press the record icon to start recording"}
        </p>
      </div>
      <div className="flex gap-2 items-center justify-center">
        {/* {!selectedRecord?.id && (
          <SecondaryButton
            label={"Start new Recording"}
            onClick={resetRecordingState}
          />
        )} */}
        <PrimaryButton
          label={"Generate the Note"}
          onClick={handleSubmitResponse}
        />
         
      </div>
    </div>
  );
}
