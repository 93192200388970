import React, { useEffect, useState } from "react";
import FileUploader from "./FileUploader";
import SaveFile from "./SaveFile";
import axios from "axios";
import { toast } from "react-toastify";
import { PrimaryButton } from "../components/Global/Button";
export interface Template {
  id: string;
  name: string;
}

interface CustomTemplateDialogProps {
  handleCloseCustomModal: () => void;
  handleSelectedCustomTemplate?: (template: Template) => void;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  showUploadModal: boolean;
  handleAddTemplate: (fileContent: string, fileName: string) => void;
  handleUploadModal: () => void;
}

export default function CustomTemplateDialog({
  handleCloseCustomModal,
  handleSelectedCustomTemplate,
  templates,
  setTemplates,
  handleAddTemplate,
  showUploadModal,
  handleUploadModal,
}: CustomTemplateDialogProps) {
  console.log("🚀 ~ templates:", templates);
  const [fileContent, setFileContent] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [originalFileName, setOriginalFileName] = useState<string>("");
  // const [templates, setTemplates] = useState<Template[] | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );
  console.log(
    "🚀 ~ CustomTemplateDialog ~ selectedTemplateId:",
    selectedTemplate
  );

  const handleRadioChange = (template: { id: string; name: string }) => {
    setSelectedTemplate(template);
  };

  const handleSave = () => {
    if (!selectedTemplate) {
      alert("Please select a template first.");
      return;
    }
    handleSelectedCustomTemplate({
      id: selectedTemplate.id,
      name: selectedTemplate.name,
    });
    handleCloseCustomModal();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="bg-white rounded shadow-md p-6 w-full max-w-md relative">
        <h2 className="text-xl font-semibold mb-4">Saved Templates</h2>

        <ul className="mb-6 max-h-32 overflow-y-auto">
          {templates?.map((template) => (
            <li key={template.id} className="mb-2 flex items-center">
              <input
                type="radio"
                name="selectedTemplate"
                id={`template-${template.id}`}
                value={template.id}
                checked={selectedTemplate?.id === template.id}
                onChange={() => handleRadioChange(template)}
                className="mr-2"
              />
              <label htmlFor={`template-${template.id}`}>{template.name}</label>
            </li>
          ))}
        </ul>
        <div className="py-2">
          <PrimaryButton
            label={"Select Template"}
            onClick={handleSave}
            isDisabled={!selectedTemplate?.id}
          />
        </div>

        <div className=" border-b last:border-b-0"></div>
        <FileUploader
          handleUploadModal={handleUploadModal}
          setFileContent={setFileContent}
          setFileName={setFileName}
          setOriginalFileName={setOriginalFileName}
        />

        <button
          onClick={handleCloseCustomModal}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          ✕
        </button>
      </div>

      {showUploadModal && (
        <SaveFile
          fileContent={fileContent}
          fileName={fileName}
          setFileName={setFileName}
          handleCloseUploadModal={handleUploadModal}
          originalFileName={originalFileName}
          handleAddTemplate={handleAddTemplate}
        />
      )}
    </div>
  );
}
