import React, { useState, useRef, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useSpeechRecognition } from "react-speech-recognition";
import "../../styles/RecordingArea.css";
import Loader from "./Loader";
import axios from "axios";
import RecordGreenIcon from "../../assets/record-icon-green.png";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import useRecordingStore from "../../utils/recordingStore";
import { useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../../utils/userStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PatientInformation from "./PatientInformation";
import RecordingSection from "./RecordingSection";
import { trackEvent } from "../../firebase";
import { jwtDecode } from "jwt-decode";
import RecordingAreaTabs from "../../containers/RecordingAreaTabs";
import { useAudioRecorder } from "react-audio-voice-recorder";

const RecordingArea = ({ sectionName }) => {
  const recorderControls = useAudioRecorder();
  const [isPatientForm, setIsPatientForm] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [pauseRecording, setPauseRecording] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [userTranscript, setUserTranscript] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunkTimeoutRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formattedSOAP, setFormattedSOAP] = useState();
  const [formattedPromptSOAP, setFormattedPromptSOAP] = useState();
  const setSearchName = useRecordingStore((state) => state.setSearchName);
  const [tempSearchName, setTempSearchName] = useState("");
  const [selectedOption, setSelectedOption] = useState("SOAP");
  const [reloadData, setReloadData] = useState(false);
  const currentUser = useUserStore((state) => state.currentUser);
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [transcriptionText, setTranscriptionText] = useState("");
  const [recordingId, setRecordingId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [activeTab, setActiveTab] = useState("transcript");
  const [selectedCustomTemplate, setSelectedCustomTemplate] = useState("");
  const recordings = useRecordingStore((state) => state.recordings);
  const setRecordings = useRecordingStore((state) => state.setRecordings);
  const location = useLocation();
  const navigate = useNavigate();
  const section = location.state?.section
    ? location.state?.section
    : sectionName;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSelectedCustomTemplate = (template) => {
    setSelectedCustomTemplate(template);
  };

  // Start/stop recording based on recording state, pause flag, and apiKey availability.
  useEffect(() => {
    if (isRecording && !pauseRecording && apiKey) {
      startRecording();
    } else {
      stopRecording();
    }
    // Cleanup on component unmount
    return () => {
      stopRecording();
    };
  }, [isRecording, pauseRecording, apiKey]);

  useEffect(() => {
    if (selectedRecord?.id) setUserTranscript(selectedRecord?.transcript);
  }, [selectedRecord]);

  useEffect(() => {
    if (selectedRecord?.id) {
      setIsPatientForm(true);
      stopRecording();
      setFormattedSOAP(selectedRecord.soap_note);
      setRecordingId(selectedRecord.id);

      if (selectedRecord.soap_note1) {
        setFormattedPromptSOAP(selectedRecord.soap_note1);
      } else {
        setFormattedPromptSOAP("");
      }
      setPatientName(selectedRecord.patient_name);
    }
  }, [selectedRecord]);

  useEffect(() => {
    async function getDeepgramApiKey() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          // throw new Error("User not authenticated");
          toast.error("User not authenticated");
          navigate("/login");
        }
        const token = await user.getIdToken();

        const response = await fetch(
          `${process.env.REACT_APP_BE_DOMAIN}/deepgram`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const token = data.token;
          const decoded = jwtDecode(token);
          const key = decoded.apiKey;
          setApiKey(key);
        } else {
          const errorData = await response.json();
          console.error("Error:", errorData);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }

    if (isRecording) {
      let isMounted = true;
      getDeepgramApiKey();

      const intervalId = setInterval(
        () => {
          getDeepgramApiKey();
        },
        1 * 60 * 1000 + 50 * 1000
      );

      return () => {
        isMounted = false;
        clearInterval(intervalId);
      };
    }
  }, [isRecording]);

  useEffect(() => {
    if (selectedRecord) {
      setSelectedSpecialization(selectedRecord.selected_specialization);
      setSelectedOption(selectedRecord.note_type);
      setSelectedCustomTemplate({
        id: selectedRecord.selected_custom_template_id,
      });
    }
  }, [selectedRecord]);

  useEffect(() => {
    setUserTranscript(transcriptionText);
  }, [transcript]);

  const checkMicrophonePermission = async () => {
    if (!navigator.permissions) return false; // Some browsers don't support this API

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });
      return permissionStatus.state === "granted"; // Returns true if permission is already granted
    } catch (error) {
      console.warn("Permission API not supported:", error);
      return false;
    }
  };
  // New approach: record a complete 4-second chunk with a new MediaRecorder instance each time.
  const startRecording = async () => {
    if (pauseRecording) {
      return;
    }
    recorderControls.startRecording();
    // if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    //   console.error("getUserMedia is not supported on this device/browser.");
    //   return;
    // }
    const hasPermission = await checkMicrophonePermission();

    if (!hasPermission) {
      console.log("Requesting microphone permission...");
    }
    if (!streamRef.current) {
      try {
        streamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } catch (err) {
        console.error("Error accessing the microphone: ", err);
        return;
      }
    }
    recordChunk();
  };

  const recordChunk = () => {
    if (!isRecording || pauseRecording || !streamRef.current) {
      return;
    }
    const mimeType = MediaRecorder.isTypeSupported("audio/webm")
      ? "audio/webm"
      : "audio/mp4";
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      console.error(`${mimeType} is not supported on your browser.`);
      return;
    }
    const recorder = new MediaRecorder(streamRef.current, { mimeType });
    mediaRecorderRef.current = recorder;

    recorder.addEventListener("dataavailable", async (event) => {
      console.log("🚀 ~ event:", event);
      if (event.data.size > 0) {
        console.log("Blob type:", event.data.type);
        await sendAudioForTranscription(event.data);
      }
    });

    recorder.addEventListener("stop", () => {
      if (isRecording && !pauseRecording) {
        // Start the next 4-second chunk
        recordChunk();
      }
    });

    recorder.start();
    // Stop the recorder after 4 seconds to produce a complete file
    chunkTimeoutRef.current = setTimeout(() => {
      if (recorder.state !== "inactive") {
        recorder.stop();
      }
    }, 4000);
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    if (chunkTimeoutRef.current) {
      clearTimeout(chunkTimeoutRef.current);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    recorderControls.stopRecording();
  };

  const resetRecordingState = () => {
    stopRecording();
    resetTranscript();
    setPatientName("");
    setSelectedSpecialization("");
    setUserTranscript("");
    setFormattedSOAP();
    setFormattedPromptSOAP();
    setIsRecording(false);
    setSelectedRecord(null);
    setRecordingId(null);
    setIsPatientForm(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // throw new Error("User not authenticated");
      toast.error("User not authenticated");
      navigate("/login");
    }
    const token = await user.getIdToken();

    // const storage = getStorage();
    // const storageRef = ref(
    //   storage,
    //   `audios/${user.uid}_${patientName}_${Date.now()}.wav`
    // );

    try {
      // const audioURL = "sbf";

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio`,
        {
          transcript: userTranscript,
          // audioUrl: audioURL,
          patientName: patientName,
          userId: user.uid,
          noteType: selectedOption,
          selectedSpecialization: selectedSpecialization,
          physicianName: currentUser.name,
          selectedCustomTemplateId: selectedCustomTemplate?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        trackEvent("User", "Recording", "Creating new Recording");
        setRecordingId(response.data.recordingId);
        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");
      handleTabClick("notes");
      if (selectedRecord?.isDraft) {
        setRecordings(
          recordings.filter(
            (note) => !(note.isDraft && note.id === selectedRecord.id)
          )
        );
        const existingDrafts =
          JSON.parse(localStorage.getItem("draftNotes")) || [];
        const updatedDrafts = existingDrafts.filter(
          (note) => !(note.isDraft && note.id === selectedRecord.id)
        );
        localStorage.setItem("draftNotes", JSON.stringify(updatedDrafts));
      }
      stopRecording();
      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");

      const currentTime = new Date();
      const timestamp = {
        _seconds: Math.floor(currentTime.getTime() / 1000),
        _nanoseconds: (currentTime.getTime() % 1000) * 1000000,
      };
      const draftNote = {
        id: `draft-${new Date().getTime()}`, // temporary ID
        transcript: userTranscript,
        // audioUrl: audioURL,
        patient_name: patientName,
        user_id: user.uid,
        note_type: selectedOption,
        selected_specialization: selectedSpecialization,
        physician_name: currentUser.name,
        selected_custom_template_id: selectedCustomTemplate?.id,
        timestamp: timestamp,
        isDraft: true,
      };

      setRecordings([draftNote, ...recordings]);

      const existingDrafts =
        JSON.parse(localStorage.getItem("draftNotes")) || [];
      localStorage.setItem(
        "draftNotes",
        JSON.stringify([draftNote, ...existingDrafts])
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleReSubmit = async (transcriptId) => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      toast.error("User not authenticated");
      navigate("/login");
    }
    const token = await user.getIdToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio/update`,
        {
          transcriptId: transcriptId,
          transcript: userTranscript,
          userId: user.uid,
          physicianName: currentUser.name,
          selectedCustomTemplateId: selectedCustomTemplate.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        trackEvent("User", "Recording", "Updated Recording");

        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");
      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameSearch = () => {
    setSearchName(tempSearchName);
  };

  const handleTranscriptChange = (e) => {
    setUserTranscript(e.target.value);
  };

  const handleOptionChange = (event, onClickCustom) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "custom") {
      onClickCustom();
    } else {
      handleSelectedCustomTemplate("");
    }
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const contentType = audioBlob.type || "application/octet-stream";

    try {
      const response = await fetch(
        `https://api.deepgram.com/v1/listen?model=nova-2&punctuate=true&language=${selectedLanguage}`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${apiKey}`,
            "Content-Type": contentType,
          },
          body: audioBlob,
        }
      );

      if (!response.ok) {
        const errorData = await response.text();
        console.error("Deepgram API error:", errorData);
        return;
      }

      const data = await response.json();
      handleTranscriptionResult(data);
    } catch (error) {
      console.error("Error sending audio to Deepgram:", error);
    }
  };

  const handleTranscriptionResult = (data) => {
    if (data && data.results && data.results.channels[0].alternatives[0]) {
      const transcript = data.results.channels[0].alternatives[0].transcript;
      setTranscriptionText((prev) => prev + " " + transcript);
      setUserTranscript((prev) => prev + " " + transcript);
    }
  };

  const handlePause = () => {
    setIsRecording((prev) => !prev);
    if (pauseRecording) {
      setPauseRecording(false);
    }
  };

  const handleSubmitResponse = () => {
    selectedRecord?.id && !selectedRecord.isDraft
      ? handleReSubmit(selectedRecord?.id)
      : recordingId
        ? handleReSubmit(recordingId)
        : handleSubmit();
  };

  return (
    <RecordingAreaLocked
      isLocked={currentUser?.status === "cancel" ? true : false}
    >
      {isLoading && <Loader showText={true} />}
      <div className={`flex flex-wrap`}>
        <RecordingSection
          handleNameSearch={handleNameSearch}
          reloadData={reloadData}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          tempSearchName={tempSearchName}
          setTempSearchName={setTempSearchName}
          section={section}
        />

        <div
          className={`${
            section === "history" ? "hidden" : ""
          } w-full md:w-[77%]  flex justify-center order-1 md:order-2`}
        >
          {!isPatientForm ? (
            <PatientInformation
              patientName={patientName}
              setIsPatientForm={setIsPatientForm}
              isPatientForm={isPatientForm}
              setPatientName={setPatientName}
              setPauseRecording={setPauseRecording}
              setSelectedLanguage={setSelectedLanguage}
              startRecording={startRecording}
              setIsRecording={setIsRecording}
              selectedOption={selectedOption}
              setActiveTab={setActiveTab}
              handleOptionChange={handleOptionChange}
              setSelectedSpecialization={setSelectedSpecialization}
              handleSelectedCustomTemplate={handleSelectedCustomTemplate}
              selectedTemplateName={selectedCustomTemplate?.name}
            />
          ) : (
            <div className="w-full">
              <div className="flex justify-between py-4 px-2 items-center border-b border-x-customBlack-light">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "96%",
                  }}
                >
                  <p className="text-xl font-semibold">{patientName}</p>
                  <div>
                    <SecondaryButton
                      label={"Start new Recording"}
                      onClick={resetRecordingState}
                    />
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  {selectedRecord?.id && (
                    <>
                      <a
                        href={selectedRecord?.audio_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={RecordGreenIcon}
                          alt="record icon"
                          className="cursor-pointer"
                        />
                      </a>
                      {/* <SecondaryButton
                        label={"Start new Recording"}
                        onClick={resetRecordingState}
                      /> */}
                    </>
                  )}
                </div>
              </div>

              <RecordingAreaTabs
                selectedRecord={selectedRecord}
                pauseRecording={isRecording}
                userTranscript={userTranscript}
                formattedPromptSOAP={formattedPromptSOAP}
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
                resetRecordingState={resetRecordingState}
                setSelectedSpecialization={setSelectedSpecialization}
                handleTranscriptChange={handleTranscriptChange}
                handlePause={handlePause}
                handleSubmitResponse={handleSubmitResponse}
                formattedSOAP={formattedSOAP}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                recorderControls={recorderControls}
                recordingId={recordingId}
              />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </RecordingAreaLocked>
  );
};

const RecordingAreaLocked = ({ isLocked, children }) => {
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLocked) openDialog();
  }, [isLocked]);

  const openDialog = () => {
    setDialog(true);
  };

  return (
    <div className="relative">
      {isLocked && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded flex  flex-col justify-center items-center gap-3 shadow-lg w-1/2">
            <p className="text-lg">
              Please subscribe to a plan to continue using MD Voice
            </p>
            <PrimaryButton
              label={"Get Started"}
              onClick={() => navigate("/pricing")}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default RecordingArea;
