import React, { useState } from "react";
import { PrimaryButton } from "../Global/Button";
import useUserStore from "../../utils/userStore";

export default function GeneratedPromptView({
  formattedSOAP,
  formattedPromptSOAP,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    // logEvent(analytics, "copied_text");
    const cleanedSoapNote = copyText?.toString().replace(/\*/g, "");

    let text = "";
    text += cleanedSoapNote;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500); // reset copied text after 2 seconds
  };

  const currentUser = useUserStore((state) => state.currentUser);
  console.log(currentUser);
  return (
    <div
    // className={`w-full  ${formattedPromptSOAP ? "md:w-[34%]" : "md:w-[34%]"}`}
    >
      <div className="">
        <div className="flex justify-between items-center p-2">
          <p className="font-bold text-left">
            {!formattedPromptSOAP ? "Consultation Note" : "SOAP Note"}
          </p>
          <PrimaryButton
            onClick={() => copyToClipboard(formattedSOAP)}
            label={"Copy Note"}
          />
          {currentUser?.user_id === "78ruRPubqfYrQX386UhgKFa1VtH2" && (
            <PrimaryButton
              label="Send To EMR"
              onClick={() => {
                /* handle EMR logic */
              }}
            />
          )}
        </div>

        <div className="p-2">
          <TextFormatter text={formattedSOAP} />
        </div>
      </div>
      {/* {formattedPromptSOAP && (
        <div className="border border-l ">
          <div className="flex justify-between items-center p-2">
            <p className="font-bold text-left">Comprehensive SOAP Note</p>
            <PrimaryButton
              onClick={() => copyToClipboard(formattedPromptSOAP)}
              label={"Copy Note"}
            />
          </div>

          <div className="p-2">
            <TextFormatter text={formattedPromptSOAP} />
          </div>
        </div>
      )} */}
    </div>
  );
}

const TextFormatter = ({ text }) => {
  // Ensure text is a string
  text = text?.toString() || "";

  // Regular expression to match text between ** that are properly closed
  const boldRegex = /\*\*(.+?)\*\*/g;

  // Replace matched text with formatted HTML
  const formattedText = text.replace(boldRegex, (match, p1) => {
    return `<div class="my-2 border-customBlack-lighter"><br /><strong class="font-bold">${p1}</strong><br /></div>`;
  });

  // Remove any remaining asterisks that are not part of valid formatting
  const cleanText = formattedText.replace(/\*/g, "");

  // Render the formatted text using dangerouslySetInnerHTML
  return <div className="" dangerouslySetInnerHTML={{ __html: cleanText }} />;
};
