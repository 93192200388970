import React, { useState } from "react";
import TranscriptSection from "../components/Main/TranscriptSection";
import GeneratedPromptView from "../components/Main/GeneratedPromptView";
import { ReferralTabs } from "../components/referral/ReferralTabs";
import useUserStore from "../utils/userStore";

type TabOption = "transcript" | "notes" | "referrals";

export default function RecordingAreaTabs({
  selectedRecord,
  pauseRecording,
  userTranscript,
  formattedPromptSOAP,
  resetRecordingState,
  handleTranscriptChange,
  handlePause,
  handleSubmitResponse,
  formattedSOAP,
  activeTab,
  handleTabClick,
  recorderControls,
  recordingId,
}) {
  const currentUser = useUserStore((state) => state.currentUser);

  return (
    <div className="p-4">
      <div className="flex gap-4 border-b border-gray-200">
        <button
          onClick={() => handleTabClick("transcript")}
          className={`px-4 py-2  rounded-t-lg border-t-2 ${
            activeTab === "transcript"
              ? "bg-customBlue-primary text-white border-customBlue-primary"
              : "bg-gray-200 text-gray-600 border-transparent"
          }`}
        >
          Transcript
        </button>
        <button
          onClick={() => handleTabClick("notes")}
          className={`px-4 py-2  rounded-t-lg border-t-2 ${
            activeTab === "notes"
              ? "bg-customBlue-primary text-white border-customBlue-primary"
              : "bg-gray-200 text-gray-600 border-transparent"
          }`}
        >
          Notes
        </button>
        {currentUser?.user_id === "78ruRPubqfYrQX386UhgKFa1VtH2" && (
          <button
            onClick={() => handleTabClick("referrals")}
            className={`px-4 py-2  rounded-t-lg border-t-2 ${
              activeTab === "referrals"
                ? "bg-customBlue-primary text-white border-customBlue-primary"
                : "bg-gray-200 text-gray-600 border-transparent"
            }`}
          >
            Referrals
          </button>
        )}
      </div>

      <div className="mt-4">
        {activeTab === "transcript" && (
          <TranscriptSection
            selectedRecord={selectedRecord}
            pauseRecording={pauseRecording}
            userTranscript={userTranscript}
            formattedPromptSOAP={formattedPromptSOAP}
            //   selectedOption={selectedOption}
            //   handleOptionChange={handleOptionChange}
            resetRecordingState={resetRecordingState}
            //   setSelectedSpecialization={setSelectedSpecialization}
            handleTranscriptChange={handleTranscriptChange}
            handlePause={handlePause}
            handleSubmitResponse={handleSubmitResponse}
            recorderControls={recorderControls}
          />
        )}

        {activeTab === "notes" && (
          <GeneratedPromptView
            formattedPromptSOAP={formattedPromptSOAP}
            formattedSOAP={formattedSOAP}
          />
        )}

        {activeTab === "referrals" && (
          <ReferralTabs report={formattedSOAP} recordingId={recordingId} />
        )}
      </div>
    </div>
  );
}
