import React, { useEffect, useState } from "react";
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../styles/Auth.css";
import Layout from "../components/Layout";
import LoginImg from "../assets/login.jpg";
import Loader from "../components/Main/Loader";
import CryptoJS from "crypto-js";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../components/Global/PasswordInput";
import { trackEvent } from "../firebase";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Set to true initially
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication state
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authUser, async (user) => {
      if (user) {
        console.log("User is already authenticated:", user);
        setIsAuthenticated(true);

        try {
          await user.getIdToken(true); // Force refresh token

          // Redirect only if coming from another page
          const from = location.state?.from?.pathname;
          if (from) {
            navigate(from === "/settings" ? "/settings#emr-info" : from, {
              replace: true,
            });
          } else {
            navigate("/main", { replace: true });
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
          toast.error("Session expired. Please log in again.");
          await signOut(authUser);
          navigate("/login");
        }
      }

      setIsLoading(false); // Auth check complete
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Invalid Phone number");
      setIsLoading(false);
      return;
    }

    try {
      const hashedPassword = CryptoJS.SHA256(password).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/login`,
        {
          password: hashedPassword,
          phone_number: phoneNumber,
        }
      );

      const token = response.data.token;
      await signInWithCustomToken(authUser, token);
      trackEvent("User", "Login", "User logged in");

      navigate("/main");
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error(error.response?.data?.result || "Login failed");
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="auth-container">
      <form className="auth-form md:p-2 p-4" onSubmit={handleLogin}>
        <div className="flex flex-col gap-4 text-left">
          <div>
            <h1 className="text-h1 font-h1 text-customBlack-primary">Log in</h1>
            <p className="text-p font-p text-customBlack-secondary">
              Welcome back! Please enter your details.
            </p>
          </div>

          <div>
            <p>Phone Number</p>
            <PhoneInput
              international
              countryCallingCodeEditable={true}
              defaultCountry="US"
              placeholder={"Phone number"}
              value={phoneNumber}
              onChange={setPhoneNumber}
              style={{
                borderRadius: "6px",
                paddingLeft: "10px",
                width: "inherit",
              }}
            />
          </div>
          <div>
            <PasswordInput
              label="Password"
              placeholder="Enter password"
              value={password}
              handleOnChange={(e) => setPassword(e.target.value)}
              isRequired={true}
            />
            <Link to="/forgot-password"> Forgot Password?</Link>
          </div>
          <button type="submit">Sign in</button>
          <p>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

const Login = () => {
  return <Layout children={<LoginForm />} img={LoginImg} />;
};

export default Login;
