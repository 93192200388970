import { create } from "zustand";

const useRecordingStore = create((set) => ({
  selectedDate: null,
  searchName: "",
  recordings: [],

  setSelectedDate: (selectedDate) => set({ selectedDate }),
  setSearchName: (searchName) => set({ searchName }),
  setRecordings: (recordings) => set({ recordings }),
}));

export default useRecordingStore;
