import React, { useState, useEffect } from "react";
import { Referral } from "./ReferralTabs";
import { PrimaryButton, SecondaryButton } from "../Global/Button";

interface GeneratedReferralsListProps {
  referrals: Referral[];
  setActiveTab: React.Dispatch<React.SetStateAction<"add" | "list">>;
}

const GeneratedReferrals: React.FC<GeneratedReferralsListProps> = ({
  referrals,
  setActiveTab,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [openReferrals, setOpenReferrals] = useState<string[]>(
    referrals.length > 0 ? [referrals[0].id] : []
  );
  const toggleReferral = (id: string) => {
    if (openReferrals.includes(id)) {
      setOpenReferrals(openReferrals.filter((item) => item !== id));
    } else {
      setOpenReferrals([...openReferrals, id]);
    }
  };

  const copyToClipboard = (copyText) => {
    const cleanedReferral = copyText?.toString().replace(/\*/g, "");

    let text = "";
    text += cleanedReferral;
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="">
      {loading && <p>Loading referrals...</p>}
      {error && <p className="text-red-500">Error: {error}</p>}
      {referrals.map((referral, index) => (
        <div key={index} className="border rounded mb-2">
          <button
            onClick={() => toggleReferral(referral.id)}
            className="w-full flex gap-2 text-left px-4 py-2 bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            {`Referral ${index + 1}`}
            <div>{openReferrals.includes(referral.id) ? "▼" : "▶"}</div>
          </button>
          {openReferrals.includes(referral.id) && (
            <div className="p-4 bg-white">
              <div className="flex justify-end px-2 gap-2">
                <SecondaryButton
                  label="Copy Referral"
                  onClick={() => copyToClipboard(referral.referral)}
                />
                <PrimaryButton
                  label="Send to EMR"
                  // onClick={handleGenerateReferral}
                  disabled={loading}
                />
              </div>
              <p>{referral.referral}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GeneratedReferrals;
