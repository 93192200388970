import React, { useEffect, useState } from "react";
// import {
//   HashRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useNavigate,
// } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/LandingPage.css";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import LandingPage from "./containers/LandingPage";
import Navbar from "./components/Global/Navbar";
import Sidebar from "./components/Sidebar";
import UserLayout from "./components/UserLayout";
import UserNavbar from "./components/Global/UserNavbar";
import { initializeAnalytics } from "./analytics";
import StripeContainer from "./components/Main/StripeContainer";
import Success from "./components/Main/Succes";
import Cancel from "./components/Main/Cancel";
import Settings from "./containers/Settings";
import PricingPage from "./components/Main/PricingPage";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./components/Auth/ForgotPassword";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import DeleteAccount from "./components/Main/DeleteAccount";
import TermConditions from "./containers/TermConditions";
import ContactTable from "./components/Main/ContactUsTable";
import LuckyDraw from "./containers/LuckyDraw";
import RecordingArea from "./components/Main/RecordingArea";
import { toast } from "react-toastify";
import FAQ from "./components/Main/sections/FAQ";
import OurTeam from "./components/Main/sections/OurTeam";
import ContactForm from "./components/Main/sections/ContactForm";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
      if (!navigator.onLine) {
        toast.error("🚨 No Internet Connection!", { autoClose: 3000 });
      }
      const handleOnline = () => {
        setIsOnline(true);
        // toast.success("✅ Back Online!", { autoClose: 3000 });
      };
      const handleOffline = () => {
        setIsOnline(false);
        toast.error("🚨 No Internet Connection!", { autoClose: 3000 });
      };

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);

    return isOnline;
  };

  useNetworkStatus();
  // useEffect(() => {
  //   initializeAnalytics();
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <div className="App font-sans">
      <ToastContainer />

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Navbar />
                <LandingPage />
              </div>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/contact-us"
            element={
              <div>
                <ProtectedRoute>
                  <ContactTable />
                </ProtectedRoute>
              </div>
            }
          />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/lucky-draw" element={<LuckyDraw />} />
          <Route path="/terms-and-conditions" element={<TermConditions />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<UserLayout />}>
            <Route
              path="/dashboard"
              element={
                <div>
                  <ProtectedRoute>
                    <UserNavbar />
                    <LandingPage />
                  </ProtectedRoute>
                </div>
              }
            />
            <Route
              path="/main"
              element={
                <ProtectedRoute>
                  <div>
                    {/* <UserNavbar /> */}
                    {/* <Sidebar /> */}
                    <RecordingArea sectionName={"main"} />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing"
              element={
                <ProtectedRoute>
                  {/* <UserNavbar /> */}
                  <PricingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  {/* <UserNavbar /> */}
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/subscription"
            element={
              <ProtectedRoute>
                <StripeContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <Success />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cancel"
            element={
              <ProtectedRoute>
                <Cancel />
              </ProtectedRoute>
            }
          />

          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // Remove UID if not logged in
        localStorage.removeItem("uid");
        navigate("/login", { state: { from: location } });
      } else {
        // Store UID in local storage
        localStorage.setItem("uid", user.uid);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, [navigate]);

  return children;
};

initializeAnalytics();

export default App;
