// ContactForm.js

import React from "react";
import { useFormik } from "formik";
import Input from "../../Global/Input";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import "../../../styles/Auth.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { trackEvent } from "../../../analytics";
import Navbar from "../../Global/Navbar";

const contactSchema = Yup.object({
  name: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
});

function ContactForm() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      note: "",
    },
    validationSchema: contactSchema,

    onSubmit: async (values) => {
      try {
        if (!isValidPhoneNumber(formik.values.phone) && formik.values.phone) {
          toast.error("Invalid Phone number");
          return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BE_DOMAIN}/user/contact-us`,
          {
            name: values.name,
            email: values.email,
            phone_number: values.phone,
            note: values.note,

            headers: {
              "Content-Type": "multipart/form-data",
              // 'Authorization': `Bearer ${token}`
            },
          }
        );
        if (response.data) {
          toast.success("Submitted succesfully");
          trackEvent("User", "ContactUs", "New contact us query");

          // Handle success
          console.log("Form submitted successfully");
          formik.resetForm();
        } else {
          // Handle error
          console.error("Error submitting form");
          toast.error("Error submitting information");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    },
  });

  const handlePhoneChange = (val, index) => {
    if (val) {
      formik.setFieldValue("phone", val);
    } else {
      formik.setFieldValue("phone", "");
    }
  };

  const isFormEmpty =
    !formik.values.name &&
    !formik.values.email &&
    !formik.values.phone &&
    !formik.values.note;
  return (
    <div>
      <Navbar />
      <div className="flex justify-center items-center mt-7">
        <form
          onSubmit={formik.handleSubmit}
          className="w-full max-w-2xl rounded-lg auth-form bg-gray-100 p-8"
        >
          <h2 className="text-3xl font-extrabold text-center mb-8">
            Contact Us
          </h2>
          <div>
            <Input
              name="name"
              label="Name"
              type="text"
              placeholder="Enter your name"
              props={{ ...formik.getFieldProps("name") }}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            ) : null}
          </div>

          <div>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
              props={{ ...formik.getFieldProps("email") }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <p>Phone Number</p>

            <PhoneInput
              international
              countryCallingCodeEditable={true}
              defaultCountry="CA"
              placeholder={"Phone number"}
              value={formik.values.phone}
              onChange={handlePhoneChange}
              style={{
                borderRadius: "6px",
                paddingLeft: "10px",
                width: "inherit",
              }}
            />
            {formik.touched.phone_number && formik.errors.phone ? (
              <div className="text-red-500 text-sm">{formik.errors.phone}</div>
            ) : null}
          </div>

          <div className="mb-4">
            <label htmlFor="note" className="block text-gray-700">
              Note
            </label>
            <textarea
              id="note"
              name="note"
              onChange={formik.handleChange}
              value={formik.values.note}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            ></textarea>
          </div>

          <button
            type="submit"
            disabled={isFormEmpty}
            className={`mt-4 px-4 py-2 rounded-md text-white ${
              isFormEmpty
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default ContactForm;
