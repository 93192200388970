import axios from "axios";
import React, { useState } from "react";
import PasswordInput from "../Global/PasswordInput";
import { auth } from "../../firebase";

function ContactTable() {
  const [password, setPassword] = useState("");
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("emr"); // "emr" or "contact"
  const [emrData, setEmrData] = useState([]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;

      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/contact-us-form`,
        {
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        setError("Incorrect password or network error");
      }
      const contactForm = response.data;
      contactForm.sort((a, b) => {
        if (a.timestamp._seconds === b.timestamp._seconds) {
          return b.timestamp._nanoseconds - a.timestamp._nanoseconds;
        }
        return b.timestamp._seconds - a.timestamp._seconds;
      });

      setData(contactForm);
      setPasswordSubmitted(true);

      const responseEMR = await axios.get(
        `${process.env.REACT_APP_BE_DOMAIN}/emr-info/all`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (responseEMR.data) {
        setEmrData(responseEMR.data.doctorEmrInfo);
      }
      console.log(
        "🚀 ~ handlePasswordSubmit ~ responseEMR.data:",
        responseEMR.data
      );
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  if (!passwordSubmitted) {
    return (
      <div className="flex items-center justify-center h-screen">
        <form
          onSubmit={handlePasswordSubmit}
          className="bg-white p-6 rounded shadow-md"
        >
          <h2 className="text-2xl mb-4">Enter Password</h2>
          {error && <p className="text-red-500 mb-2">{error}</p>}
          <PasswordInput
            label="Password"
            placeholder="Enter password"
            value={password}
            handleOnChange={(e) => setPassword(e.target.value)}
            isRequired={true}
          />
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 w-full rounded hover:bg-blue-600"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="p-4">
      {/* Tabs */}
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => setActiveTab("emr")}
          className={`px-4 py-2 border-b-2 ${
            activeTab === "emr" ? "border-blue-500" : "border-transparent"
          }`}
        >
          EMR FORM
        </button>
        <button
          onClick={() => setActiveTab("contact")}
          className={`px-4 py-2 border-b-2 ${
            activeTab === "contact" ? "border-blue-500" : "border-transparent"
          }`}
        >
          Contact US Form
        </button>
      </div>

      {activeTab === "emr" && (
        <div>
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="border px-4 py-2 text-left">Phone Number</th>
                <th className="border px-4 py-2 text-left">Username</th>
                <th className="border px-4 py-2 text-left">Password</th>
                <th className="border px-4 py-2 text-left">URL</th>
                <th className="border px-4 py-2 text-left">Email</th>
              </tr>
            </thead>
            <tbody>
              {emrData?.map((emrInfo, index) => (
                <tr key={index} className="border">
                  <td className="border px-4 py-2">
                    {emrInfo.phone_number || ""}
                  </td>
                  <td className="border px-4 py-2">{emrInfo.username || ""}</td>
                  <td className="border px-4 py-2">{emrInfo.password || ""}</td>
                  <td className="border px-4 py-2">{emrInfo.url || ""}</td>
                  <td className="border px-4 py-2">{emrInfo.email || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "contact" && (
        <div>
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="border px-4 py-2 text-left">Name</th>
                <th className="border px-4 py-2 text-left">Date</th>
                <th className="border px-4 py-2 text-left">Email</th>
                <th className="border px-4 py-2 text-left">Phone Number</th>
                <th className="border px-4 py-2 text-left">Note</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((contact, index) => (
                <tr key={index} className="border">
                  <td className="border px-4 py-2">{contact.name || ""}</td>
                  <td className="border px-4 py-2">
                    {contact.timestamp
                      ? new Date(
                          contact.timestamp._seconds * 1000
                        ).toLocaleString()
                      : ""}
                  </td>
                  <td className="border px-4 py-2">{contact.email || ""}</td>
                  <td className="border px-4 py-2">{contact.phone || ""}</td>
                  <td className="border px-4 py-2">{contact.note || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ContactTable;
